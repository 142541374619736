/**
 * Heroslider
 */

.tm-heroslider {
    position: relative;
    background-position: right center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex !important;
    align-items: center;

    &-area {
        position: relative;
    }

    &-content {
        padding: 100px 0;

        h1 {
            margin-top: -10px;
            font-size: 50px;
            line-height: 64px;
        }

        p {
            font-size: 20px;
            line-height: 30px;
            margin-top: 30px;
            color: $color-heading;
        }

        .tm-button {
            margin-top: 25px;
        }
    }

    &-image {
        margin-right: -325px;
    }

    &-social {
        padding-left: 0;
        margin-bottom: 0;
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        z-index: 5;
        text-align: center;
        padding: 15px 0;

        li {
            list-style: none;
            display: inline-block;
            margin: 0;
            position: relative;

            a {
                color: $color-heading;
                display: inline-block;
                font-size: 18px;
                text-align: center;
                background: transparent;
                padding: 5px;

                &:hover {
                    color: $color-theme;
                }
            }

            span {
                position: absolute;
                left: 100%;
                top: 50%;
                line-height: 20px;
                margin-top: -10px;
                visibility: hidden;
                opacity: 0;
                padding-left: 10px;
                font-size: 16px;
                color: $color-theme;
            }

            &:hover {
                span {
                    visibility: visible;
                    opacity: 1;
                }
            }

        }

        @media only screen and (max-width: 1350px) {
            display: none;
        }
    }

    @media #{$layout-desktop-xs} {
        &-image {
            margin-right: -180px;
        }
    }

    @media #{$layout-laptop} {
        &-content {
            padding-right: 130px;

            h1 {
                font-size: 40px;
                line-height: 52px;
            }
        }

        &-image {
            margin-right: 0px;
            margin-left: -135px;
        }
    }

    @media #{$layout-notebook} {
        &-content {
            padding-right: 80px;

            h1 {
                font-size: 34px;
                line-height: 44px;
            }
        }

        &-image {
            margin-right: 0px;
            margin-left: -100px;
        }
    }

    @media #{$layout-tablet} {
        &-content {
            padding-right: 80px;

            h1 {
                font-size: 30px;
                line-height: 40px;
            }

            p {
                font-size: 18px;
                line-height: 26px;
                margin-top: 20px;
            }

            .tm-button {
                margin-top: 15px;
            }
        }

        &-image {
            margin-right: -30px;
            margin-left: -100px;
        }
    }

    @media #{$layout-mobile-lg} {

        &-content {
            padding-right: 0;
            padding-top: 30px;

            h1 {
                font-size: 28px;
                line-height: 38px;
            }

            p {
                font-size: 18px;
                line-height: 26px;
                margin-top: 20px;
            }

            .tm-button {
                margin-top: 15px;
            }
        }

        &-image {
            margin-right: 100px;
            margin-left: 0px;
            margin-top: 30px;
        }
    }

    @media #{$layout-mobile-sm} {

        &-content {
            h1 {
                font-size: 30px;
                line-height: 38px;
            }

            p {
                font-size: 16px;
                line-height: 24px;
            }
        }

        &-image {
            margin-right: 0;
        }
    }
}

.tm-heroslider-slider {
    &.slick-dotted {
        padding-bottom: 0;

        .slick-dots {
            position: absolute;
            left: 0;
            top: auto;
            bottom: 30px;
            z-index: 5;

            li {
                background: $color-body;
                width: 30px;
                height: 3px;
                border-radius: 0;

                &:hover {
                    opacity: 0.5;
                    background: $color-theme;
                }

                &.slick-active {
                    background: $color-theme;
                }
            }
        }
    }

    .slick-arrow {
        top: auto;
        bottom: 70px;
        background: transparent;
        color: $color-body;
        height: 30px;
        width: 30px;
        font-size: 45px;
        transform: scale(1);
        transition: $transition;

        &.slick-prev {
            left: auto;
            right: calc(50% + 50px);
        }

        &.slick-next {
            right: auto;
            left: calc(50% + 50px);
        }

        &:hover {
            background: transparent;
            color: $color-theme;
            transform: scale(1.2);
        }
    }

    .tm-heroslider {
        &-contentwrapper {
            overflow: hidden;
        }

        &-content {
            transition: all 1s ease-in-out 0.5s;
            transform: translateX(-100%);
        }

        &.slick-current {
            .tm-heroslider-content {
                transform: translateX(0);
            }
        }
    }

    @media #{$layout-notebook} {
        &.slick-dotted {
            .slick-dots {
                bottom: 20px;
            }
        }

        .slick-arrow {
            bottom: 45px;
            font-size: 35px;
        }
    }

    @media #{$layout-tablet} {
        &.slick-dotted {
            .slick-dots {
                bottom: 20px;
            }
        }

        .slick-arrow {
            bottom: 45px;
            font-size: 35px;
        }
    }

    @media #{$layout-mobile-lg} {
        &.slick-dotted {
            .slick-dots {
                bottom: 20px;
            }
        }

        .slick-arrow {
            bottom: 27px;
            font-size: 30px;
        }
    }
}


.tm-heroslider-pagination {
    position: absolute;
    top: auto;
    left: 50%;
    bottom: 50px;
    height: 55px;
    width: 55px;
    margin-left: -27.5px;

    svg {
        display: inline-block;
        height: 100%;
        width: 100%;
        overflow: visible;

        .radialbg {
            stroke: #aaaaaa;
            stroke-width: 1px;
            fill: none;
        }

        .radialactive {
            stroke: $color-theme;
            stroke-width: 2px;
            stroke-linecap: circle;
            fill: none;
            transform: rotate(-90deg);
            transform-origin: center;
            transition: all 0.5s ease-in-out 0s;
        }
    }

    .slides-numbers {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        letter-spacing: 1px;
    }

    @media only screen and (max-width: 1599px) {
        bottom: 30px;
    }

    @media only screen and (max-width: 767px) {
        bottom: 15px;
    }
}