/**
 * Form
 */

.tm-form {

    &-inner {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: -30px;
    }

    &-field {
        margin-top: 30px;
        flex: 0 0 100%;
        max-width: 100%;

        a {
            color: $color-body;

            &:hover {
                color: $color-theme;
            }
        }
    }

    &-fieldhalf {
        flex: 0 0 calc(50% - 15px);
        max-width: calc(50% - 15px);
    }

    label {
        font-family: $ff-body;
        font-size: 15px;
        color: $color-heading;
        line-height: 22px;
    }

    input,
    textarea,
    select {
        border: 1px solid darken($color-border, 10);
        background: transparent;
        @include placeholder-color($color-body);
        color: $color-body;
        border-radius: 4px;

        &:focus {
            border-color: rgba($color-theme, 0.3);
        }
    }

    textarea {
        min-height: 162px;
    }

    .nice-select {
        border: 1px solid darken($color-border, 10);
        background: transparent;
        color: $color-body;
        border-radius: 3px;

        &.open {
            border-color: rgba($color-theme, 0.3);
        }
    }

    @media #{$layout-mobile-sm} {

        &-fieldhalf {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}