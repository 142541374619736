/**
 * Button
 */

.tm-button,
a.tm-button,
button.tm-button {
    display: inline-block;
    height: 50px;
    min-width: 150px;
    background: $color-secondary;
    color: #ffffff;
    text-align: center;
    line-height: 50px;
    font-weight: 600;
    border-radius: 4px;
    z-index: 1;
    position: relative;
    overflow: hidden;
    padding: 0 40px;
    vertical-align: middle;
    font-size: 16px;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: -100%;
        height: 100%;
        width: 100%;
        background: $color-heading;
        z-index: -1;
        transition: all 0.4s ease-in-out 0s;
    }

    &:hover {
        &::before {
            top: 0;
        }
    }

    &.tm-button-dark {
        background: $color-heading;

        &::before {
            background: $color-secondary;
        }
    }

    &.tm-button-white {
        background: #ffffff;
        color: $color-heading;

        &::before {
            background: $color-secondary;
        }

        &:hover {
            color: #ffffff;
        }
    }

    &.tm-button-transparent {
        background: transparent;
        color: $color-secondary;
        border: 1px solid $color-secondary;

        &::before {
            background: $color-secondary;
        }

        &:hover {
            color: #ffffff;
        }

        &.tm-button-white {
            border-color: #ffffff;
            color: #ffffff;

            &::before {
                background: #ffffff;
            }

            &:hover {
                color: $color-heading;
            }
        }

        &.tm-button-dark {
            border-color: $color-heading;
            color: $color-heading;

            &::before {
                background: $color-heading;
            }

            &:hover {
                color: #ffffff;
            }
        }
    }

    &.tm-button-small {
        height: 45px;
        line-height: 45px;
        min-width: 100px;
        padding: 0 25px;
    }

    @media #{$layout-mobile-lg} {
        height: 45px;
        line-height: 45px;
        min-width: 100px;
        padding: 0 25px;
    }
}


/* Readmore */
.tm-readmore,
a.tm-readmore,
button.tm-readmore {
    display: inline-block;
    font-weight: 700;
    font-family: $ff-body;
    color: $color-body;

    &::after {
        content: "\f3d6";
        font-family: 'Ionicons';
        font-size: 18px;
        line-height: 1;
        padding-left: 10px;
        transition: $transition;
    }

    &:hover {
        color: $color-theme;

        &::after {
            padding-left: 15px;
        }
    }
}



/* Video Button */
.tm-videobutton,
a.tm-videobutton,
button.tm-videobutton {
    display: inline-block;
    border-radius: 1000px;
    vertical-align: middle;
    text-align: center;
    height: 80px;
    width: 80px;

    img {
        height: 100%;
        width: 100%;
    }

    @media #{$layout-mobile-lg} {
        height: 60px;
        width: 60px;
    }
}


.tm-buttongroup {
    margin-left: -10px;
    margin-top: -10px;
    font-size: 0;

    .tm-button {
        margin-left: 10px;
        margin-top: 10px;
    }
}