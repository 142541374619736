/**
 * Offer
 */

.tm-offer {
    padding: 50px 0;
    padding-left: 170px;
    background-position: center right;
    position: relative;
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: #fff;
        opacity: 0.7;
        z-index: -1;
        display: none;
    }

    h6 {
        margin-bottom: 5px;
        font-weight: 600;

    }

    h1 {
        font-weight: 700;
        margin-bottom: 20px;

        span {
            color: $color-theme;
            font-size: 55px;
        }
    }

    .tm-button {
        margin-top: 30px;
    }

    @media #{$layout-notebook} {
        padding-left: 120px;
    }

    @media #{$layout-tablet} {
        padding-left: 30px;
    }

    @media #{$layout-mobile-lg} {
        padding-left: 30px;

        &::before {
            display: block;
        }
    }

    @media #{$layout-mobile-sm} {
        padding-left: 15px;
        padding-right: 15px;
    }
}

/* Countdown */
.tm-countdown {
    margin-top: -10px;

    &-pack {
        min-width: 70px;
        min-height: 70px;
        text-align: center;
        background: #ffffff;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        box-shadow: $shadow;
        position: relative;

        &:not(:last-child) {
            margin-right: 10px;
        }

        h2 {
            margin-top: 5px;
            margin-bottom: 0;
            line-height: 1;
            font-size: 30px;
        }

        h6 {
            font-size: 15px;
            font-family: $ff-body;
            font-weight: 400;
            margin-bottom: 0;
            color: $color-body;
        }
    }
}