/**
 * Service
 */

.tm-service {
    background-color: transparent;
    width: 100%;
    height: 250px;
    perspective: 800px;

    &-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        transform: rotateY(0) translateZ(0);
    }

    &-frontside {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        backface-visibility: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        transform: translateZ(0);

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: #000000;
            opacity: 0.55;
            z-index: -1;
        }

        h6 {
            color: #ffffff;
            font-weight: 700;
            font-family: $ff-body;
            margin-bottom: 0;
        }
    }

    &-backside {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: $color-secondary;
        color: #ffffff;
        padding: 45px 30px;
        z-index: 1;
        transform: rotateY(180deg) translateZ(0);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        a {
            color: #ffffff;
        }

        h6 {
            color: #ffffff;
            font-weight: 700;
            font-family: $ff-body;
        }

        a.tm-readmore {
            color: #ffffff;
        }
    }

    &:hover &-inner {
        transform: rotateY(180deg) translateZ(0);
    }
}



.tm-provide {
    &-content {
        h2 {
            margin-top: -7px;
        }
    }

    &-image {
        margin-top: -100px;
        margin-bottom: -100px;
    }

    @media #{$layout-tablet} {
        &-image {
            margin-top: 40px;
            margin-bottom: 0;
        }
    }

    @media #{$layout-mobile-lg} {
        &-image {
            margin-top: 40px;
            margin-bottom: 0;
        }
    }
}


.tm-service-details {

    img {
        margin-bottom: 30px;
    }

    @media #{$layout-mobile-lg} {
        .f-right {
            float: none;
        }

        .pl-3 {
            padding-left: 0 !important;
        }
    }
}