/**
 * Navigation
 */

.tm-header-nav {
    ul {
        margin-bottom: 0;
        padding-left: 0;

        li {
            list-style: none;
            display: inline-block;
            margin: 0 18px;
            position: relative;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            a {
                display: inline-block;
                padding: 15px 0;
                color: $color-heading;
                font-family: $ff-body;
                font-weight: 600;
                font-size: 16px;
            }

            &.current,
            &:hover {
                &>a {
                    color: $color-theme;
                }
            }
        }
    }

    .tm-header-nav-dropdown {
        &>a {
            &::after {
                content: "\f123";
                font-family: 'Ionicons';
                font-size: 12px;
                padding-left: 3px;
                vertical-align: middle;
            }
        }

        ul {
            position: absolute;
            text-align: left;
            padding: 10px 0;
            left: 100%;
            top: 0;
            width: 250px;
            background: #ffffff;
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
            border-top: 4px solid $color-theme;
            visibility: hidden;
            opacity: 0;
            z-index: -1;
            border-radius: 0 0 3px 3px;
            transition: all 0.1s ease-in-out 0s;

            li {
                display: block;
                padding: 0 15px;
                transition: all 0.1s ease-in-out 0s;
                margin: 0;

                a {
                    padding: 6px 0;
                    line-height: 1.3;
                    display: block;
                    font-weight: 400;
                    font-size: 14px;
                    color: $color-body;
                }

                &.has-child {
                    @include clearfix();

                    &>a {
                        &::after {
                            content: "\f125";
                            font-family: 'Ionicons';
                            font-size: 12px;
                            float: right;
                        }
                    }
                }

                &:hover {
                    &>a {
                        color: $color-theme;
                    }

                    &>ul {
                        visibility: visible;
                        opacity: 1;
                        z-index: 1;
                    }
                }
            }
        }

        &>ul {
            left: 0;
            top: 100%;
        }

        &:hover {
            &>ul {
                visibility: visible;
                opacity: 1;
                z-index: 9;
            }
        }

        &.overflow-element {
            ul {
                left: auto;
                right: 100%;
            }

            &>ul {
                left: auto;
                right: 0;
            }
        }
    }

    @media #{$layout-notebook} {
        ul {
            li {
                margin: 0 11px;
            }
        }
    }
}