// Colors 
$color-theme: #34a5b3;
$color-secondary: #13788a;
$color-body: #777777;
$color-heading: #111111;
$color-border: #ebebeb;
$color-grey: #f9fafc;
$color-dark : #111111;
$color-black: #000000;
$color-white: #ffffff;


// Font Family
$ff-body: 'Hind', sans-serif;
$ff-heading: 'Montserrat', sans-serif;


// Font Size
$fz-body: 15px;
$fz-heading-1: 36px;
$fz-heading-2: 32px;
$fz-heading-3: 26px;
$fz-heading-4: 22px;
$fz-heading-5: 20px;
$fz-heading-6: 18px;


// Line Height
$lh-body: 26px;
$lh-heading-1: 48px;
$lh-heading-2: 42px;
$lh-heading-3: 40px;
$lh-heading-4: 34px;
$lh-heading-5: 30px;
$lh-heading-6: 28px;


// Input Button Height
$input-button-height: 50px;

$transition: all 0.4s ease-in-out;
$shadow: 0 2px 10px rgba(#222222, 0.15);

// Layouts
$layout-dektop : 'only screen and (min-width: 1920px)';
$layout-desktop-xs: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$layout-laptop : 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$layout-notebook: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$layout-tablet : 'only screen and (min-width: 768px) and (max-width: 991px)';
$layout-mobile-lg : 'only screen and (max-width: 767px)';
$layout-mobile-sm : 'only screen and (max-width: 575px)';
$layout-mobile-xs : 'only screen and (max-width: 480px)';



// Social Colors
$facebook: #3b5999;
$messenger: #0084ff;
$twitter: #55acee;
$google-plus: #dd4b39;
$pinterest: #bd081c;
$linkedin: #0077B5;
$flickr: #ff0084;
$dribbble: #ea4c89;
$instagram: #e4405f;
$google: #DD4B39;
$skype: #1ab7ea;
$behance: #0057ff;
$youtube: #cd201f;
$vimeo: #86C9EF;
$yahoo: #410093;
$paypal: #00588B;
$delicious: #205CC0;
$flattr: #F67C1A;
$android: #A4C639;
$tumblr: #34465d;
$wikipedia: #333333;
$stumbleupon: #eb4924;
$foursquare: #f94877;
$digg: #191919;
$spotify: #81B71A;
$reddit: #ff5700;
$cc: #688527;
$vk: #4c75a3;
$rss: #EE802F;
$podcast: #E4B21B;
$dropbox: #007ee5;
$github: #171515;
$soundcloud: #ff3300;
$wordpress: #21759b;
$yelp: #af0606;
$scribd: #666666;
$cc-stripe: #008CDD;
$drupal: #27537A;
$slideshare: #0077b5;
$get-pocket: #EE4056;
$bitbucket: #0E4984;
$stack-overflow: #EF8236;
$hacker-news: #ff6600;
$xing: #126567;
$quora: #b92b27;
$openid: #E16309;
$steam: #111111;
$amazon: #E47911;
$weibo: #df2029;
$blogger: #f57d00;
$whatsapp: #25D366;
$wechat: #09b83e;
$line: #00c300;
$medium: #02b875;
$vine: #00b489;
$slack: #3aaf85;
$snapchat: #FFFC00;