/**
 * Pricebox
 */

.tm-pricebox {
    box-shadow: $shadow;
    overflow: hidden;
    position: relative;

    &-header {
        padding: 30px 50px;
        position: relative;
        z-index: 1;
        background-image: url(../images/icons/pricebox-bg-shape.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom center;

        svg {
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
        }

        h2 {
            margin-top: -5px;
            border-bottom: 1px solid rgba(#ffffff, 0.5);
            color: #ffffff;
            padding-bottom: 10px;
        }

        h3 {
            color: #ffffff;
            font-size: 30px;
            font-weight: 700;
            margin-bottom: 0;

            span {
                font-size: 18px;
                display: block;
                font-family: $ff-body;
                font-weight: 400;
                line-height: 1;
            }
        }

    }

    &-badge {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
        background: #ffffff;
        font-weight: 700;
        font-size: 18px;
        color: $color-heading;
        padding: 2px 60px;
        transform-origin: center center;
        transform: rotate(45deg) translate(30%, -85%);
    }

    &-body {
        padding: 30px 20px;
        padding-top: 20px;

        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;

            li {
                font-size: 18px;

                &:not(:last-child) {
                    padding-bottom: 6px;
                }
            }
        }

        .tm-button {
            margin-top: 15px;
        }
    }

    @media #{$layout-notebook} {
        &-header {
            padding: 30px 20px;
        }
    }

    @media #{$layout-tablet} {
        &-header {
            padding: 30px 20px;

            h3 {
                font-size: 28px;
            }
        }

        &-body {
            ul {
                li {
                    font-size: 16px;
                }
            }
        }
    }

    @media #{$layout-mobile-lg} {
        &-header {
            h3 {
                font-size: 26px;
            }
        }

        &-body {
            ul {
                li {
                    font-size: 16px;
                }
            }
        }
    }

    @media #{$layout-mobile-sm} {
        &-header {
            padding: 30px 20px;
        }
    }
}