/**
 * Title
 */

.tm-sectiontitle {
    margin-bottom: 55px;
    
    h2 {
        margin-top: -6px;
        margin-bottom: 12px;
    }

    &-divider {
        display: inline-block;
        max-width: 18px;
        position: relative;
        line-height: 1;
        margin-bottom: 20px;

        &::before,
        &::after {
            content: url(../images/icons/icon-divider-wave.png);
            position: absolute;
            width: 60px;
            top: 50%;
            transform: translateY(-50%);
        }

        &::before {
            left: auto;
            right: calc(100% + 10px);
        }

        &::after {
            left: calc(100% + 10px);
            right: auto;
        }

        img {
            width: 100%;
        }
    }

    p {
        margin-bottom: 0;
    }
}