/**
 * Tabgroup
 */

.tm-tabgroup {
    margin-bottom: 45px;
    background: $color-grey;
    border-radius: 1000px;
    display: inline-flex;
    justify-content: center;

    li {
        margin-top: 1px;
        margin-right: 1px;

        a {
            display: inline-block;
            height: 50px;
            line-height: 50px - 2;
            min-width: 170px;
            text-align: center;
            padding: 0 35px;
            color: #333333;
            font-size: 18px;
            font-weight: 700;
            border-radius: 100px;

            &:hover {
                color: $color-theme;
            }

            &.active {
                background: $color-theme;
                color: #ffffff;
            }
        }
    }

    @media #{$layout-tablet} {
        li {
            a {
                padding: 0 25px;
                font-size: 16px;
                background: $color-grey;
                min-width: inherit;
            }
        }
    }

    @media #{$layout-mobile-lg} {
        background: transparent;

        li {
            width: 100%;

            a {
                display: block;
                height: 45px;
                line-height: 43px;
                padding: 0 25px;
                font-size: 16px;
                background: $color-grey;
                min-width: inherit;
            }
        }
    }
}


.tm-tabgroup2 {
    padding-left: 0;
    margin-bottom: 30px;
    padding: 0;
    display: block;
    border-bottom: 1px solid #c7c7c7;

    li {
        list-style: none;
        display: inline-block;

        &:not(:last-child){
            margin-right: 50px;
        }

        a {
            color: $color-heading;
            font-size: 20px;
            font-weight: 600;
            display: inline-block;
            padding: 0;
            padding-bottom: 10px;
            position: relative;

            &::after{
                content: "";
                position: absolute;
                left: 0;
                top: 100%;
                height: 1px;
                width: 100%;
                background: $color-secondary;
                transition: $transition;
                transform-origin: 0 0;
                transform: scale(0);
            }

            &.active {
                &::after{
                    transform: scale(1);
                }
            }
        }
    }

    @media #{$layout-mobile-sm} {
        li {
            &:not(:last-child){
                margin-right: 30px;
            }
        }
    }
}


.tab-content {
    width: 100%;

    .tab-pane {
        display: block;
        height: 0;
        max-width: 100%;
        visibility: hidden;
        overflow: hidden;
        opacity: 0;

        &.active {
            height: auto;
            visibility: visible;
            opacity: 1;
            overflow: visible
        }
    }
}